import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'
import '../../utils/style/AnimationTurnComponent.css'

// header container
const HeaderContainer = styled.div`
    display:flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    top:0;
    width: 100%;
`

const ProgressContainer = styled.div`
    align-self: center;
    width: 95%;
    height: 0.5px;
    background: white;

    @media(min-width: 1200px) and (min-height : 690px)    {
        font-size: 1.2rem;
        height: 1px;
    }

`

const ProgressBar = styled.div`
  height: 0.5px;
  background: rgb(170, 170, 170);
  width: 0%;

  @media(min-width: 1200px) and (min-height : 690px)    {
        font-size: 1.2rem;
        height: 1px;
    }
`

// flex nav global
const HeaderGlobalStyleNAV = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    backdrop-filter: blur(10px);
    /* background-color: black; */
`

// company name
const H1 = styled.h1`
    color: white;
    margin: 1rem 1rem;
    font-size: 0.65rem;
    text-align: center;

    @media(min-width: 1200px) and (min-height : 690px)    {
        font-size: 0.7rem;
    }

    @media (min-width: 1800px){
        font-size: 1.1rem;
        margin: 2rem 2rem;
    }

    @media (min-width: 2800px){
        font-size: 2.1rem;
        margin: 4rem 4rem;
    }
`

// global div of link
const StyledNavLinkDIV = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4rem;

    @media (max-width: 1000px){
        margin-left: 0rem;
    }

    @media(min-width: 1200px) and (min-height : 690px)    {
        margin-left: 0rem;
    }
`

// Link of navbar
const StyledHashLink = styled(NavLink)`
    position:relative;
    text-decoration: none;
    white-space: nowrap;
    transition:0.2s;
    color: white;
    margin-left: 0.75rem;
    font-weight: bold;
    font-size: 0.75rem;
    text-align: center;
    padding: 0 0.4rem;
    border-radius: 30px;

    @media (min-width: 1000px){
        font-size: 0.85rem;
    }
    
    @media (min-width: 1800px){
        font-size: 1.75rem;
    }

    @media (min-width: 2800px){
        font-size: 3.5rem;
        padding: 0 1.2rem;
    }

    &::before {
            display: inline-block;
            position: absolute;
            vertical-align: middle;
            left: 0;
            color: rgb(170, 170, 170);
            padding-left: 20%;
            opacity: 0;
            transform: translateX(0%); // 0% to -100%
            -webkit-transition: all 0.25s ease-out;
            -moz-transition: all 0.25s ease-out;
            -ms-transition: all 0.25s ease-out;
            -o-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
            content: "["
        }

        &::after{
            display: inline-block;
            position: absolute;
            vertical-align: middle;
            right:0;
            color: rgb(170, 170, 170);
            padding-right: 20%;
            opacity: 0;
            transform: translateX(0%); // 0% to 100%
            -webkit-transition: all 0.25s ease-out;
            -moz-transition: all 0.25s ease-out;
            -ms-transition: all 0.25s ease-out;
            -o-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
            content: "]"
        }

    &:hover{
        color: rgb(170, 170, 170);
        transition:  all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &::before {

            opacity: 1;
            transform: translateX(-100%); // 0% to -100%
        }

        &::after{

            opacity: 1;
            transform: translateX(100%); // 0% to 100%

        }
    }

    color: ${(props) => props.$activate ? "rgb(170, 170, 170)" : ""};
`

const IMG = styled.img.attrs(props => ({
    src:  (props.$activate || props.$iconIsHover) ?  "/data/images/logo/header/home.webp" : "/data/images/logo/header/homew.webp",
  }))`
    width: 45px;
    height: 15px;

    @media (min-width: 1800px){
        width: 80px;
        height: 30px;
    }

    @media (min-width: 2800px){
        width: 160px;
        height: 60px;
    }

    &:hover{
        src: "/data/images/logo/header/home.webp";
    }
  `;

const ALinkedin = styled.a`

color: white;
transition : color 0.2s;

&:hover{
    color:#087BB9;
}

@media (min-width: 1800px){
        font-size: 1.5rem;
    }

    @media (min-width: 2800px){
        font-size: 2.5rem;
    }
`

const IMGLogo = styled.img`
    width:20px;
    height:20px;
    display: inline-block;
    /* transition: transform 0.3s ease; */

    @media (min-width: 1800px){
        width:30px;
        height:30px;
    }
    @media (min-width: 2800px){
        width:60px;
        height:60px;
    }
    `


function HeaderComputer() {

    const { ContainerViewer } = useContext(ThemeContext)
    const [iconIsHover, setIconIsHover] = useState(false); 

    const HandleMouseEnter = () =>{
        setIconIsHover(true)
    }

    const HandleMouseLeave = () =>{
        setIconIsHover(false)
    }

    // scroll progressive bar
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {

        const handleScroll = () => {

            // update scroll progressive bar state
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.clientHeight;

            const totalScroll = documentHeight - windowHeight;
            const currentScrollPercentage = (scrollY / totalScroll) * 100;

            setScrollPercentage(currentScrollPercentage);
        };

    window.addEventListener('scroll', handleScroll);

    return () => {window.removeEventListener('scroll', handleScroll);};}, []);
    
    return (

        <HeaderContainer>

            <HeaderGlobalStyleNAV>
            
                <H1 >Sébastien Petit</H1>
                <IMGLogo onMouseEnter={(e) => e.currentTarget.classList.add('rotate360Class') } onAnimationEnd={(e) => e.currentTarget.classList.remove('rotate360Class')} src="/data/images/logo/header/logo.webp" alt="logo du site"></IMGLogo>
                <H1>Développeur Web & Ingénieur Robotique</H1>

                <ALinkedin style={{marginRight:"1rem"}} href="https://www.linkedin.com/in/s%C3%A9bastien-petit-rob/" target="_blank" rel="noreferrer">  <i className="fa-brands fa-linkedin-in fa-1x"></i> </ALinkedin>
                {/* <AThreads style={{marginRight:"1rem"}} href="https://www.threads.net/@sebastiengoodspeed" target="_blank" rel="noreferrer">  <i className="fa-brands fa-threads fa-1x"></i> </AThreads> */}
                {/* <AInsta href="https://www.instagram.com/sebastiengoodspeed/" target="_blank" rel="noreferrer">  <i className="fa-brands fa-instagram fa-1x"></i> </AInsta> */}

                <StyledNavLinkDIV>
                    <StyledHashLink $activate={ContainerViewer[0]} to="/#home">Accueil</StyledHashLink>
                    <StyledHashLink style={{marginTop:"0%"}} $activate={ContainerViewer[1]} to="/#submarine3d"><IMG onMouseEnter={HandleMouseEnter} onMouseLeave={HandleMouseLeave} $iconIsHover={iconIsHover} $activate={ContainerViewer[1]}></IMG></StyledHashLink>
                    <StyledHashLink $activate={ContainerViewer[2]} to="/#servicesWeb">Création de Sites & Applications Web SaaS</StyledHashLink>
                    <StyledHashLink $activate={ContainerViewer[3]} to="/#servicesRob">Développement Robotique</StyledHashLink>
                    <StyledHashLink $activate={ContainerViewer[4]} to="/#work">Portfolio</StyledHashLink>
                    <StyledHashLink $activate={ContainerViewer[5]} to="/#contact">Contact & Tarifs</StyledHashLink>
                    <StyledHashLink $activate={ContainerViewer[6]} to="/#about">À Propos</StyledHashLink>
                    
                    
                </StyledNavLinkDIV>

            </HeaderGlobalStyleNAV>

            <ProgressContainer>
                <ProgressBar style={{ width: `${scrollPercentage}%` }}></ProgressBar>
            </ProgressContainer>

        </HeaderContainer>

    )
}

export default HeaderComputer