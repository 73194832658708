import styled from 'styled-components'
import BlockIcon from "./BlockIcon"
import colors from '../../utils/style/color'

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    padding-top: 2.5rem;
    background-color: ${colors.TextBackground};
    
`
// use aos package
const BlockPresentationIconWeb = () => {

    return (

        <FlexContainerDIV data-aos="fade-right" data-aos-duration = "800">

            <BlockIcon key={"1x"} imgPath={"/data/images/logo/logoBlockWeb/surMesure.webp"} title={"Sur-mesure"} text={"Site web ou application taillé sur mesure, entièrement codé à la main."}></BlockIcon>
            <BlockIcon key={"4x"} imgPath={"/data/images/logo/logoBlockWeb/tel.webp"} title={"Responsive design"} text={"Compatible mobile et tablette pour une expérience utilisateur optimale."}></BlockIcon>
            <BlockIcon key={"3x"} imgPath={"/data/images/logo/logoBlockWeb/loupe.webp"} title={"Référencement SEO"} text={"Visibilité accrue avec l'optimisation du référencement sur les moteurs de recherche type google."}></BlockIcon>
            <BlockIcon key={"8x"} imgPath={"/data/images/logo/logoBlockWeb/design.webp"} title={"UI/UX"} text={"Réalisation et/ou intégration de l'UI (interfaces graphiques, incluant logo, palettes de couleurs et typographie) et de l'UX (expérience utilisateur – création de parcours de navigation fluides et intuitifs) pour la conception de sites et applications web personnalisés."}></BlockIcon>
            <BlockIcon key={"6x"} imgPath={"/data/images/logo/logoBlockWeb/cadenas.webp"} title={"Sécurité et hébergements"} text={"Incorporation du protocole sécurisé HTTPS et prise en charge de l'hébergement et du nom de domaine."}></BlockIcon>
            <BlockIcon key={"5x"} imgPath={"/data/images/logo/logoBlockWeb/threed.webp"} title={"3D web"} text={"Conception d'applications web analytiques 3D. Services de création d'animations de base avec Blender et intégration de vos animations et effets 3D."}></BlockIcon>
            <BlockIcon key={"2x"} imgPath={"/data/images/logo/logoBlockWeb/optimisation.webp"} title={"Optimisation"} text={"Optimisation du projet : Rapidité d'éxécution, robustesse, web-design et sécurité."}></BlockIcon>
            <BlockIcon key={"7x"} imgPath={"/data/images/logo/logoBlockWeb/maintenance.webp"} title={"Support Maintenance"} text={"Accompagnement pendant toute la durée du projet et maintenance continue. Un contrat de maintenance (TMA) est proposé avec un tarif horaire ou annuel adaptée à la taille de votre projet."}></BlockIcon>
            
        </FlexContainerDIV>

    )
  }
  
  export default BlockPresentationIconWeb;