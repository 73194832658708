import styled from 'styled-components'
import colors from '../../utils/style/color'
import React, {useState, useRef, useEffect} from 'react'

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 1rem 3vw;
    background-color: rgb(42,42,42);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

`

const Img = styled.img`
    position:relative;
    display: flex;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: ${(props) => props.$portraitOrLandscape ? "80vw" : "35vw"};
    object-fit: contain;
    object-position: bottom;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 2;
    opacity: ${(props) => props.$switchToggle ? "0.7" : "1"};
`

const DivFilterImg = styled.div`
    position:absolute;
    display: flex;
    align-items: center; 
    justify-content: center; 
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: ${(props) => props.$portraitOrLandscape ? "80vw" : "35vw"};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: ${(props) => props.$isBackgroundEnable ? props.$sizeHeightImg + "px" : "0px" };
    background-color: black;
    z-index: 3;
    opacity: 0.4;
    transition: 0.5s all;

`

const TxtContainer = styled.div`
    display:flex;
    font-size: ${(props) => props.$portraitOrLandscape ? "0.75rem" : "1rem"};
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${colors.TextBackgroundLow};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media (min-width: 1800px){
        font-size: 1.5rem;
    }
    @media (min-width: 2800px){
        font-size: 3rem;
    }
`

const Span = styled.span`
    display: block;
    width: 10vw;
    height: 2px;
    border: solid 1px;
    border-radius: 2px;
    margin: ${(props) => props.$portraitOrLandscape ? "0.5rem" : "1rem"};
    background-color: ${colors.BackgroundTitleRobPart};
    box-shadow: ${(props) => (props.$colorLine) ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 32px rgb(230, 130, 42),inset 0 0 32px rgb(230, 130, 42)" : "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 32px rgb(144, 200, 253),inset 0 0 32px rgb(144, 200, 253);"};
`

const TxtResumeContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(33, 33, 33);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.9s;
    width: ${(props) => props.$portraitOrLandscape ? "80vw" : "35vw"};
    font-size: 0.85rem;

    &.active {
        height: auto;
        max-height: 1100px;
    }

    @media (min-width: 1800px){
        font-size: 1.3rem;
    }
    @media (min-width: 2800px){
        font-size: 2.6rem;
        &.active {
        max-height: 1700px;
    }
    }

`;

const P = styled.p`
    text-align: justify;
    margin: 0rem 1rem 1rem 1rem;
`

const DivNeon = styled.div`
    border: solid 2px;
    margin: 0.5rem;
    padding: 0.75rem;
    border-radius: 10px;
    box-shadow: ${(props) => (props.$colorLine) ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 32px rgb(230, 130, 42),inset 0 0 32px rgb(230, 130, 42)" : "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 32px rgb(144, 200, 253),inset 0 0 32px rgb(144, 200, 253);"};
`

const H1 = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    font-weight: bold;

    @media (min-width: 1800px){
        font-size: 1.5rem;
    }
    @media (min-width: 2800px){
        font-size: 3rem;
    }
`

const Button = styled.button`
    letter-spacing: 0.15rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color:white;
    border: solid 2px;
    border-color: ${colors.contactBackground};
    border-radius: 10px;
    cursor: pointer;

    &:hover{
        background-color: ${colors.contactBackground};
        border-color: rgb(50,50,50);

    }

`

const PEnable = styled.p`
    font-size: 5rem;
    color: ${(props) => (props.$colorLine) ? "rgb(230, 130, 42)" : "rgb(144, 200, 253)"};
    cursor: pointer;
    user-select: none;

`

const Blockmodel = ({portraitOrLandscape, imgPath, activityName, type, details, skills, skillsDetails, link, enabled}) => {

    let colorLine=false;

    const [switchToggle, setSwitchToggle] = useState(false);

    const imgRef = useRef(null);

    const [sizeHeightImg, setSizeHeightImg] = useState(0);

    const [isHovered, setIsHovered] = useState(false);

    const [isBackgroundEnable, setIsBackgroundEnable] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {

        if (!portraitOrLandscape){

            if(isHovered===true && switchToggle===false) {setIsBackgroundEnable(true)}
            else if(isHovered===true && switchToggle===false) {setIsBackgroundEnable(true)}
            else if(isHovered===false && switchToggle===true) {setIsBackgroundEnable(true)}
            else if(isHovered===true && switchToggle===true) {setIsBackgroundEnable(true)}
            else {setIsBackgroundEnable(false)}

        }

        else {setIsBackgroundEnable(true)}

    }, [isHovered, switchToggle]);

    useEffect(() => {

        // Capturer la valeur actuelle de la référence dans une constante
        const imgElement = imgRef.current;

        // Fonction pour mettre à jour les dimensions de l'image
        const updateSize = () => {
          if (imgElement) {
            setSizeHeightImg(imgRef.current.offsetHeight);
          }
        };
    
        // Ajouter un écouteur d'événement pour s'assurer que la taille est mise à jour si l'image est chargée après le montage du composant
        if (imgElement) {
            imgElement.addEventListener('load', updateSize);
        }
    
        // Appeler updateSize au cas où l'image serait déjà chargée avant l'ajout de l'écouteur
        updateSize();
    
        // Nettoyer l'écouteur d'événement
        return () => {
          if (imgElement) {
            imgElement.removeEventListener('load', updateSize);
          }
        };
      }, []); // Ce useEffect ne dépend d'aucune variable d'état, donc il ne s'exécute qu'au montage
    

    const handleClick = () => {
        setSwitchToggle(!switchToggle);
      };

    if (type === 'DÉVELOPPEMENT WEB'){
        colorLine=true;
    }

    const listSkills = skills.map((skill,i) => <li key={i} style={{marginBottom:"0.5rem"}}>{skill}</li>);
    const listskillsDetails = skillsDetails.map((skillDetails, i) => <DivNeon key={i} $colorLine={colorLine}>{skillDetails}</DivNeon>);

    return (

        <FlexContainerDIV $portraitOrLandscape={portraitOrLandscape} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

            <Img ref={imgRef} $switchToggle={switchToggle} $portraitOrLandscape={portraitOrLandscape} src={imgPath} alt="images logo web develloppement et robotique"></Img>

            <DivFilterImg $isBackgroundEnable={isBackgroundEnable} $sizeHeightImg={sizeHeightImg} $portraitOrLandscape={portraitOrLandscape}>
            {(isBackgroundEnable) &&
                <PEnable $colorLine={colorLine}>+</PEnable>
            }
            </DivFilterImg>

            <TxtResumeContainer $portraitOrLandscape={portraitOrLandscape} className={switchToggle ? 'active' : ''}>

                {(enabled) &&
                    <div style={{display:"flex", flexDirection:"column", flexWrap:"wrap", alignItems:"center"}}>

                        <H1>CONTEXTE</H1>
                        <P><b></b>{details}</P>
                        <Span $portraitOrLandscape={portraitOrLandscape} $colorLine={colorLine}></Span>

                        <H1>TYPE DE DÉVELOPPEMENT</H1>
                        <ul style={{marginLeft:"1.5rem"}}>{listSkills}</ul>
                        <Span $portraitOrLandscape={portraitOrLandscape} $colorLine={colorLine}></Span>

                        <H1>COMPETENCES</H1>
                        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"center", marginBottom:"2rem"}}>
                        {listskillsDetails}
                        </div>
                        <Span $portraitOrLandscape={portraitOrLandscape} $colorLine={colorLine}></Span>

                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <Button>VISITER LE SITE</Button>
                        </a>

                    </div>
                }

            </TxtResumeContainer>

            <TxtContainer $portraitOrLandscape={portraitOrLandscape}>
                <h1 style={{paddingTop:"1rem"}}>{activityName}</h1>
                <Span  $portraitOrLandscape={portraitOrLandscape} $colorLine={colorLine}></Span>
                <h2 style={{paddingBottom:"1rem"}}>{type}</h2>

            </TxtContainer>

            
        </FlexContainerDIV>

    )
  }
  
  export default Blockmodel;