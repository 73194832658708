import styled from 'styled-components'
import colors from "../../utils/style/color"
import React from 'react'

// flex container global
const GlobalContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    background-color: ${colors.TextBackground};
    color:white;
    padding-bottom: 3rem;
`

const TextTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
`

const H1 = styled.h1`
    position: relative;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: bold;
    text-align: center;
    padding-top: 3.5rem;

    text-shadow: 0 0 10px #fff,0 0 13px #fff,0 0 16px #fff,0 0 20px #fff;
    
    @media (max-width: 800px){
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    @media (min-width: 1800px){
        font-size: 4rem;
    }
    @media (min-width: 2800px){
        font-size: 8rem;
    }

`

const H2 = styled.h2`
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;

    @media (max-width: 800px){
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        margin-bottom: 2rem;
    }

    @media (min-width: 1800px){
        font-size: 2.5rem;
    }
    @media (min-width: 2800px){
        font-size: 5rem;
    }
`

const TextTitleContainerSub = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
`

const ContainerDIVBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub1 = styled.div`
    display:flex;
    flex-direction:column;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub2 = styled(ContainerDIVBlockSub1)`
        margin-left: 4rem;

        @media (max-width: 800px){
            margin-left: 0rem;
    }

`

const P1 = styled.div`
    font-size: 1rem;
    font-weight: 300;
    text-align: justify;
    margin-top: 2rem;
    width: 40vw;
    margin-left: auto; 
    margin-right: auto;

    @media (max-width: 800px){
        width: 100%;
        max-width: 80%; 
        margin-bottom: 1.5rem;
        margin-top:0rem;
    }

        @media (min-width: 1800px){
        font-size: 1.6rem;
    }
    @media (min-width: 2800px){
        font-size: 3.2rem;
    }
`

const P2 = styled(P1)`
    @media (max-width: 800px){
        margin-top: 0rem;
    }
`

const PTitle = styled.p`
font-size: 1.4rem;

@media (min-width: 1800px){
        font-size: 1.6rem;
    }
    @media (min-width: 2800px){
        font-size: 3.2rem;
    }
`

const AboutComponent = React.forwardRef((props,ref) => {

    

    return (<GlobalContainer ref={ref}> 

<TextTitleContainer>
            <TextTitleContainerSub>
                    <H1>A PROPOS</H1>
                    <H2>Développeur Web fullstack & Robotique</H2>

                    <ContainerDIVBlock>

                        <ContainerDIVBlockSub1>

                            <P1>
                                <PTitle style={{marginBottom:"0.5rem"}}><b>Un couteau suisse du développement Web & Robotique.</b></PTitle>
                                <b>Ingénieur Roboticien</b>, j'ai exercé dans le domaine du nucléaire français, ou j'ai collaboré au sein d'une équipe pluridisciplinaire, de la <b>conception de contrôleurs pour un drone autonome</b> à l'élaboration de <b>systèmes embarqués</b>, jusqu'à la <b>gestion algorithmique du traitement d'images 2D et 3D</b>.<br></br><br></br>
                                En 2023, <b>captivé par le monde du web</b>, j'ai décidé de poursuivre ma passion en me lançant en tant qu'entrepreneur indépendant, avec une expertise en <b>développement web fullstack et robotique</b>.

                            </P1>

                        </ContainerDIVBlockSub1>

                        <ContainerDIVBlockSub2>

                            <P1>
                                <PTitle style={{marginBottom:"0.5rem"}}><b>Un tarif adapté à votre vision</b></PTitle>
                                Que vous soyez une startup en quête d'innovation, une PME désireuse d'optimiser sa présence en ligne, ou un entrepreneur individuel à la recherche d'une solution personnalisée, je travaille à vos côtés pour définir <b>un devis adapté votre budget.</b>
                            </P1>


                        </ContainerDIVBlockSub2>

                    </ContainerDIVBlock>

                </TextTitleContainerSub>
            </TextTitleContainer>

        </GlobalContainer>);
});

export default AboutComponent
