import styled from 'styled-components'
import BlockIcon from "./BlockIcon"
import colors from '../../utils/style/color'

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    padding-top: 2.5rem;
    background-color: ${colors.TextBackground};

`
// use aos package
// 97, 167, 232 color
const BlockPresentationIconRob = () => {

    return (

        <FlexContainerDIV>

            <BlockIcon key={"1x"} imgPath={"/data/images/logo/logoBlockRob/puce.webp"} title={"Systèmes embarqués"} text={"Service d'expertise dans le développement logiciel de systèmes embarqués multi-capteurs."}></BlockIcon>
            <BlockIcon key={"2x"} imgPath={"/data/images/logo/logoBlockRob/cube.webp"} title={"Traitement des images 2D et 3D"} text={"Vision par ordinateur : Acquisition des données, traitement algorithmique des images 2D et 3D, détection d'objets ou d'obstacles, reconstitution 3D."}></BlockIcon>
            <BlockIcon key={"7x"} imgPath={"/data/images/logo/logoBlockRob/UI.webp"} title={"IHM"} text={"Réalisation des interfaces utilisateur IHM (Interface Homme-Machine) permettant aux opérateurs de contrôler et de surveiller le robot."}></BlockIcon>
            <BlockIcon key={"8x"} imgPath={"/data/images/logo/logoBlockRob/capteur.webp"} title={"Acquisitions de capteurs et traitement des données"} text={"Lecture et traitement des données capteurs."}></BlockIcon>
            <BlockIcon key={"5x"} imgPath={"/data/images/logo/logoBlockRob/automatisation.webp"} title={"Intelligence artificielle"} text={"Intégration des IAs existantes à vos projets."}></BlockIcon>
            <BlockIcon key={"4x"} imgPath={"/data/images/logo/logoBlockRob/bras.webp"} title={"Contrôleur robotique"} text={"Conception du contrôleur."}></BlockIcon>
            <BlockIcon key={"6x"} imgPath={"/data/images/logo/logoBlockRob/trajectoire.webp"} title={"Génération de trajectoire"} text={"Génération de trajectoire pour le déplacement robotique d'un point à un autre de manière fluide."}></BlockIcon>
            <BlockIcon key={"9x"} imgPath={"/data/images/logo/logoBlockRob/maintenance.webp"} title={"Support Maintenance"} text={"Accompagnement pendant toute la durée du projet et maintenance continue. Un contrat de maintenance (TMA) est proposé avec un tarif horaire ou annuel adaptée à la taille de votre projet."}></BlockIcon>
            

        </FlexContainerDIV>

    )
  }
  
  export default BlockPresentationIconRob;