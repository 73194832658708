import React, {useState,useEffect, useRef} from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import colors from "../../utils/style/color"

import ContactBlockIcon from "../contact/ContactBlockIcon"

import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// flex container global
const GlobalContainer = styled.div`
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
    background-color: ${colors.TextBackground};
`

const TextTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
`

const H1 = styled.h1`
    position: relative;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: bold;
    text-align: center;
    padding-top: 3.5rem;

    text-shadow: 0 0 10px #fff,0 0 13px #fff,0 0 16px #fff,0 0 20px #fff;
    
    @media (max-width: 800px){
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    @media (min-width: 1800px){
        font-size: 4rem;
    }
    @media (min-width: 2800px){
        font-size: 8rem;
    }

`

const H2 = styled.h2`
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    @media (max-width: 800px){
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        margin-bottom: 2rem;
    }

    @media (min-width: 1800px){
        font-size: 2.5rem;
    }
    @media (min-width: 2800px){
        font-size: 5rem;
    }
`

// form flex container
const FormUserFORM = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    color: white;
    margin-bottom: 2rem;
`

// form data container
const FormDataDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding: 0rem 1rem 1rem 1rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    @media (max-width: 800px){
        flex-direction: column;
        align-items: center;
    }
`

// form data static container
const FormStaticDataDIV = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
width: 45vw;

@media (max-width: 800px){
    width: 80vw;
}
`

// form data dynamic container
const FormDynamicDataDIV = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 45vw;

@media (max-width: 800px){
    width: 60vw;
}
`

// header text
const PForm = styled.p`
    padding: 1rem;
    margin: 2rem;
    background-color: rgba(45, 45, 50);;
    border-radius: 10px;
    border-style: solid;
    border-width: 3px;
    text-align: center;
    width: 50%;
    transition: box-shadow 1s;
    box-shadow: ${(props) => (props.$domaine === "Web") ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 13px rgb(230, 130, 42),inset 0 0 13px rgb(230, 130, 42)" : "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 13px rgb(144, 200, 253),inset 0 0 13px rgb(144, 200, 253);"};


    @media (max-width: 800px){
        padding: 0.75rem;
        margin: 1.5rem;
        text-align: center;
        font-size: 1.3rem;
        width: 75%;
    }

    @media (min-width: 1800px){
        font-size: 2rem;
    }
    @media (min-width: 2800px){
        font-size: 4rem;
    }
`

const PformModif = styled(PForm)`
    width: 75%;
    @media (max-width: 800px){
        padding: 0.75rem;
        margin: 1.5rem;
        text-align: center;
        font-size: 1.3rem;
        width: 100%;
    }
`

// Label part     
const Label = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    text-align: center;
    font-size: 0.85rem;

    @media (min-width: 1800){
        font-size: 1.5rem;
    }
    @media (min-width: 2800px){
        font-size: 3rem;
    }

`

// Input part
const Input = styled.input`
    padding: 1em;
    width: 50%;
    border-radius: 5px;
    margin: 0.5rem 0 2rem 0;
    background : ${colors.contactBackground};
    font-size: 0.8rem;
    border:none;
    color:white;

    &::placeholder {
        color: white;
        opacity:0.5;
    }

    @media (max-width: 800px){
        width: 75%;
    }

    @media (min-width: 1800px){
        font-size: 1.5rem;
    }
    @media (min-width: 2800px){
        font-size: 3rem;
    }

`

const InputSecurity = styled(Input)`
    width: 23%;

    @media (max-width: 704px){
        width: 60%;
    }
`

const SelectLeftDiv = styled.div`
    display:flex; 
    flex-direction: column; 
    margin-top: 1rem;
    width: 25%;

    @media (max-width: 800px){
        padding-right: 0rem;
        width: 50%;
    }
`

const Textarea = styled.textarea`
    resize: none;
    min-height: ${(props) => props.$IsSpecialSocialBrowser ? "150px" : "20vh"};
    padding: 1em;
    width: 75%;
    border-radius: 5px;
    margin: 0.5em 0 1em 0;
    font-size: 0.8rem;
    background : ${colors.contactBackground};
    border:none;
    font-family: 'Roboto';
    color:white;

    &::placeholder {
        color: white;
        opacity:0.5;
    }

    @media (max-width: 800px){
        width: 100%;
    }

    @media (min-width: 1800px){
        font-size: 1.5rem;
    }
    @media (min-width: 2800px){
        font-size: 3rem;
    }
`

// form submit container
const FormSubmitDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
`

const Button = styled.button`
    color: white;
    cursor:pointer;
    width: 25%;
    border-radius: 8px;
    border: solid 3px;
    font-size: 1.2rem;
    padding: 1em;
    background-color: rgb(40, 40, 40);
    box-shadow: ${(props) => (props.$domaine === "Web") ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42)" : "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253)"};
    transition: all 0.25s ease-in-out;

    &:hover{
        box-shadow: ${(props) => (props.$domaine === "Web") ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 32px rgb(230, 130, 42),inset 0 0 32px rgb(230, 130, 42)" : "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 32px rgb(144, 200, 253),inset 0 0 32px rgb(144, 200, 253);"};
        transition: all 0.3s;
    }

    @media (max-width: 704px){
        width: 67%;
        font-size: 0.9rem;
        padding: 0.7em;
        height: 4rem;
    }

    @media (min-width: 1800px){
        font-size: 2rem;
    }
    @media (min-width: 2800px){
        font-size: 4rem;
    }
`

const ButtonSecurity = styled(Button)`
    box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #ffffffa0,inset 0 0 16px #ffffffa0;
    &:hover{
        box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #ffffffa0,inset 0 0 16px #ffffffa0,0 0 32px #ffffffa0,inset 0 0 32px #ffffffa0;
    }
`

const ButtonError = styled(Button)`
    box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #a559599f,inset 0 0 16px #a559599f;
    &:hover{
        box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #a559599f,inset 0 0 16px #a559599f,0 0 32px #a559599f,inset 0 0 32px #a559599f;
    }
`

const ButtonValidate = styled(Button)`
box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #73cb8c9f,inset 0 0 16px #73cb8c9f;
&:hover{
    box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #73cb8c9f,inset 0 0 16px #73cb8c9f,0 0 32px #73cb8c9f,inset 0 0 32px #73cb8c9f;
    }
`

const FilePondModif = styled(FilePond)`
.filepond--panel-root { background-color: ${colors.contactBackground}; }

.filepond--drop-label {
    color: white;
}
font-size: 0.8rem;

@media (min-width: 1800px){
        font-size: 1.4rem;
    }
    @media (min-width: 2800px){
        font-size: 2.8rem;
    }

`

const FilepondDiv = styled.div`
    width: 80%;
    margin-top:1rem;

    @media (max-width: 800px){
        width: 113%;
    }

`

const CheckDIV = styled.div`
    width: 25%;
    padding-bottom: 1.5rem;
    font-size: 0.8rem;

    @media (max-width: 704px){
        width: 68%;
    }

    @media (min-width: 1800px){
        font-size: 1.25rem;
    }
    @media (min-width: 2800px){
        font-size: 2.5rem;
    }
`

const ContactMeContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: ${(props) => props.$isMobileVersion ? "column" : "row"};
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    background-color: ${colors.ContactMeContainer};
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
`

const Span = styled.span`
    display: block;
    width: ${(props) => props.$isMobileVersion ? "50px" : "2px"};
    height: ${(props) => props.$isMobileVersion ? "2px" : "50px"};
    border: solid 1px;
    border-radius: 3px;
    transition: box-shadow 1s;
    box-shadow: ${(props) => (props.$domaine === "Web") ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 32px rgb(230, 130, 42),inset 0 0 32px rgb(230, 130, 42)" : "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 32px rgb(144, 200, 253),inset 0 0 32px rgb(144, 200, 253);"};
`



const TextTitleContainerSub = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
`

const ContainerDIVBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub1 = styled.div`
    display:flex;
    flex-direction:column;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub2 = styled(ContainerDIVBlockSub1)`
        margin-left: 4rem;

        @media (max-width: 800px){
            margin-left: 0rem;
    }

`

const P1 = styled.div`
    font-size: 1rem;
    font-weight: 300;
    text-align: justify;
    margin-top: 2rem;
    width: 40vw;
    margin-left: auto; 
    margin-right: auto;

    @media (max-width: 800px){
        width: 100%;
        max-width: 80%; 
        margin-bottom: 1.5rem;
        margin-top:0rem;
    }

        @media (min-width: 1800px){
        font-size: 1.6rem;
    }
    @media (min-width: 2800px){
        font-size: 2rem;
    }
`

const P2 = styled(P1)`
    @media (max-width: 800px){
        margin-top: 0rem;
    }
`

const DivCutSection = styled.div`
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.35);
    height: ${(props) => props.$IsSpecialSocialBrowser ? "300px" : "35vh"};
    width: 100vw;
`

const Pcut = styled.p`
    color: white;
    font-size: 2.5rem;
    letter-spacing: 0.05rem;
    text-align: center;

    @media (max-width: 900px){
        font-size: 1.5rem;
    }

    @media (min-width: 2800px){
        font-size: 7rem;
    }
`

const SpanVar = styled.span`
    display: block;
    width: 100vw;
    height: 3px;
    border: solid 1px;
    border-radius: 2px;
    margin: ${(props) => props.$portraitOrLandscape ? "0.5rem" : "1rem"};
    background-color: ${colors.BackgroundTitleRobPart};
    box-shadow:  0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #fff,inset 0 0 16px #fff,0 0 32px #fff,inset 0 0 32px #fff;
`
const PTitle = styled.p`
font-size: 1.4rem;

@media (min-width: 1800px){
        font-size: 1.6rem;
    }
    @media (min-width: 2800px){
        font-size: 3.2rem;
    }
`

const PDescription = styled.p`
font-size: 1rem;

@media (min-width: 1800px){
        font-size: 1.3rem;
    }
    @media (min-width: 2800px){
        font-size: 2.8rem;
    }
`


const ContactComponent = React.forwardRef((props,ref) => {

    const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    
    const [checkedAgreement, setCheckedAgreement] = useState(false);
    const [securityNumber, setSecurityNumber] = useState("");
    const [desableOnMobile, setDesableOnMobile] = useState(false);

    const options = [
        { value: 'robotique', label: 'Robotique' },
        { value: 'web', label: 'Web' },
        ]
    const defaultSelectedOption = options[1];

    const [domaine, setDomaine] = useState(defaultSelectedOption);
    const handleSelectChange  = (selectedOption) => {
        setDomaine(selectedOption);
    }

    const elementRef = useRef(null);
    const { toggleValueOfsetchangeBackgroungImage, changeBackgroungImage } = useContext(ThemeContext)

  const handleScroll = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      // rect.top donne la distance entre le haut de l'élément et le haut de la fenêtre
      if(rect.top <= 0){
        toggleValueOfsetchangeBackgroungImage(true)
      }
      else if (rect.top > 0){
        toggleValueOfsetchangeBackgroungImage(false)
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Appel initial pour obtenir la position de départ
    handleScroll();

    // Cleanup à la fin de l'effet
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


    // Custom styles for the Select component
    const customStyles = {
    control: (styles) => ({
      ...styles,
      fontSize: '0.8rem',
      backgroundColor: 'rgb(60, 60, 60)', 
      border: 'none',
      outline: 'none', // Remove the outline
      color: 'rgb(255,255,255) !important',
      boxShadow: 'none',
      "@media (min-width: 1800px)": {
        ...styles["@media (min-width: 1800px)"],
        fontSize: "1.75rem",},
      
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize:"0.8rem",
        backgroundColor: state.isFocused ? 'rgb(90, 90, 90)' : 'rgb(60, 60, 60)',
        color: state.isSelected ? 'rgb(120,120,120)' : 'rgb(255,255,255)',
        "@media (min-width: 1500px)": {
            ...provided["@media (min-width: 1800px)"],
            fontSize: "1.75rem",},
      }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: 'rgb(60, 60, 60)',
      }),
    singleValue: (styles) => ({
    ...styles,
    color: 'rgb(255,255,255)', 
    }),
    container: (provided) => ({
    ...provided,
    margin: '2rem', // Set your desired margin
    }),
  };

    useEffect(() => {
        // desable hover on mobile
        if (window.innerWidth < window.innerHeight){
            setDesableOnMobile(true);
        }
    }, []);

    const handleChangeCheck = () =>{
        setCheckedAgreement(!checkedAgreement)
    }

    const [files, setFiles] = useState([])

/*     const baseUrl = "http://localhost:8081/api/sendEmail"; dev server*/

    const [isLoading, setIsLoading] = useState(false)
    const [emailSendOk, setIsEmailSendOk] = useState(false)
    const [emailSendError, setIsEmailSendError] = useState(false)
    const [isSecurityCheck, setIsSecurityCheck] = useState(false)

    const sendEmail = async event => {

        setIsLoading(true)

        // to fix issue in firefox browser. pb : time for refreshing from, is refreshing before send it
        event.preventDefault();

        if (securityNumber !== '31'){

            setIsLoading(false)
            setIsSecurityCheck(true)

            const timer = setTimeout(() => {
                setIsSecurityCheck(false)
              }, 1500);
            return () => clearTimeout(timer);
        }

        // data gestation
        const formData = new FormData()
        
        // data text group
        formData.append('field1', name)
        formData.append('field2', mail)
        formData.append('field3', tel)
        formData.append('field4', message)
        formData.append('field5', domaine.label)

        // data file group
        files.forEach((obj) => {
            formData.append('imageFile', obj.file); // Ensure that the field name matches the server-side field name
          });

        // send fetch request
        const settings = {
            method: 'POST',
            body: formData,
            }
        
        try{
        const response = await fetch("https://sebastien-petit-dev.com/api/sendEmail", settings)

        if (response.ok) {
            console.log('Email sent successfully');
            setIsLoading(false)
            setIsEmailSendOk(true)

            const timer = setTimeout(() => {
                setIsEmailSendOk(false)
              }, 1500);
              return () => clearTimeout(timer);

          } 
        else {
            console.error('Error sending email');
            setIsLoading(false)
            setIsEmailSendError(true)

            const timer = setTimeout(() => {
                setIsEmailSendError(false)
              }, 1500);
            return () => clearTimeout(timer);

        }
        }

        catch (error) {
          console.error('Error:', error);
          setIsLoading(false)
          setIsEmailSendError(true)

          const timer = setTimeout(() => {
            setIsEmailSendError(false)
          }, 1500);
          return () => clearTimeout(timer);

        }

    }

    const [isMobileVersion, setIsMobileVersion] = useState(window.innerWidth < 810);

    useEffect(() => {

        window.addEventListener( 'resize', onWindowResize );

        function onWindowResize() {

            if(window.innerWidth < 810){
                setIsMobileVersion(true)
            }
        
            else{setIsMobileVersion(false)}
          }
          
        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', onWindowResize);}

    }, [isMobileVersion]);

    return (
    <div ref={ref}>
    <GlobalContainer> 

            <TextTitleContainer>
            <TextTitleContainerSub>
                    <H1>CONTACT & TARIFS</H1>
                    <H2>Contactez moi pour recevoir une <b>estimation gratuite et sans engagement</b>.</H2>

                </TextTitleContainerSub>
            </TextTitleContainer>

            <SpanVar></SpanVar>

        <FormUserFORM onSubmit={sendEmail}>

        <SelectLeftDiv>
            <Select options={options} 
                    value={domaine}
                    onChange={handleSelectChange}
                    styles={customStyles}
                    />

        </SelectLeftDiv>

        <ContactMeContainer $isMobileVersion={isMobileVersion} ref={elementRef} id="target-element">

            <ContactBlockIcon domaine={domaine.label} imgPath={"/data/images/logo/footer/AdressLogo.webp"} text={"Sebadev, Montpellier"} link={"https://www.google.fr/maps/place/Montpellier/@43.6100088,3.8329699,13z/data=!3m1!4b1!4m6!3m5!1s0x12b6af0725dd9db1:0xad8756742894e802!8m2!3d43.610769!4d3.876716!16zL20vMGpxMjc?entry=ttu"}></ContactBlockIcon>
            <Span $isMobileVersion={isMobileVersion} $domaine={domaine.label}></Span>
            <ContactBlockIcon domaine={domaine.label} imgPath={"/data/images/logo/footer/MailLogo.webp"} text={"sebastienpetit.dev@gmail.com"} link={"mailto:sebastienpetit.dev@gmail.com"}></ContactBlockIcon>
            <Span $isMobileVersion={isMobileVersion} $domaine={domaine.label}></Span>
            <ContactBlockIcon domaine={domaine.label} imgPath={"/data/images/logo/footer/TelLogo.webp"} text={"06.10.97.10.19"}></ContactBlockIcon>
            <Span $isMobileVersion={isMobileVersion} $domaine={domaine.label}></Span>
            <ContactBlockIcon domaine={domaine.label} imgPath={"/data/images/logo/footer/LinkedinLogo.webp"} text={"Contact Linkedin"} link={"https://www.linkedin.com/in/s%C3%A9bastien-petit-rob/"}></ContactBlockIcon>
            <Span $isMobileVersion={isMobileVersion} $domaine={domaine.label}></Span>
            <ContactBlockIcon domaine={domaine.label} imgPath={"/data/images/logo/footer/HoraireLogo.webp"} text={"Du lundi au vendredi de 9h00 à 18H00"}></ContactBlockIcon>

        </ContactMeContainer>

        <FormDataDIV>

            <FormStaticDataDIV>

                <PForm $domaine={domaine.label}>Vos informations et votre message</PForm>

                <Label>Nom*</Label>
                <Input
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nom et Prénom*"
                    type='text'
                    required
                />
                

                <Label>Mail*</Label>
                <Input
                    name="mail"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder='E-Mail*'
                    type='email'
                    required
                />
                

                <Label>Téléphone</Label>
                <Input
                    name="tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                    placeholder='Téléphone'
                    type='text'
                />

            </FormStaticDataDIV>

            <FormDynamicDataDIV>

                <PformModif $domaine={domaine.label}>Informations sur le projet</PformModif>

                <Label>Message*</Label>
                <Textarea $IsSpecialSocialBrowser={IsSpecialSocialBrowser}
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Description du projet* (Merci de détailler au maximum afin de faciliter nos futurs échanges)'
                    required
                ></Textarea>

                <FilepondDiv>
                <Label style={{paddingBottom:"0.5rem"}}>Documents</Label>
                <FilePondModif
                files={files}
                allowMultiple={true}
                allowReorder={true}
                acceptedFileTypes={['image/*']}
                onupdatefiles={setFiles}
                credits={false}
                maxFiles={3}
                name="filepond" 
                
                labelIdle='Glissez & Déposez un/des documents (<b>MAX 4MB</b>) OU <span class="filepond--label-action"><b>Parcourir...<b></span>'
                />
                </FilepondDiv>

            </FormDynamicDataDIV>

        </FormDataDIV>

        <Span $isMobileVersion={isMobileVersion} $domaine={domaine.label} style={{height:"2px", width:"100px", marginTop:"1rem"}}></Span>

        <CheckDIV>
            <label>
            <input style={{marginRight:"0.5rem", marginTop:"2.5rem"}}
            name="checkedB"
            onChange={handleChangeCheck}
            checked={checkedAgreement}
            type="checkbox" 
            required
            />
            En complétant ce formulaire, je donne mon accord pour l'utilisation des informations que j'ai fournies dans le cadre de la demande de contact et des éventuelles relations commerciales qui pourraient en résulter.
            </label>
        </CheckDIV>

        <Label>Etes-vous un robot ?</Label>
        <InputSecurity
            name="securityNumber"
            value={securityNumber}
            onChange={(e) => setSecurityNumber(e.target.value)}
            placeholder='25 + 6 = ?'
            type=''
            required
        />

        <FormSubmitDIV>

        {(!isLoading && !emailSendOk && !emailSendError && !isSecurityCheck) && (
            <Button $desableOnMobile={desableOnMobile} $domaine={domaine.label} type="submit">Envoyer</Button>
        )}

        {isLoading && (
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
        )}

        {emailSendOk && (
            <ButtonValidate $desableOnMobile={desableOnMobile} disabled={true} >Message envoyé !</ButtonValidate>
        )}

        {emailSendError && (
            <ButtonError $desableOnMobile={desableOnMobile} disabled={true} >Échec, contacter moi directement.</ButtonError>
        )}

        {isSecurityCheck && (
            <ButtonSecurity $desableOnMobile={desableOnMobile} disabled={true} >Vous êtes un robot.</ButtonSecurity>
        )}

        </FormSubmitDIV>

        </FormUserFORM>

        <SpanVar></SpanVar>

        <TextTitleContainer style={{paddingBottom:"4rem"}}>
            <TextTitleContainerSub>
                    <H2>Un tarif adapté à votre budget.</H2>

                    <ContainerDIVBlock>

                        <ContainerDIVBlockSub1>

                            <P1>
                                <PTitle style={{marginBottom:"0.5rem", color: "rgb(230, 130, 42)"}}><b>Prestation Web.</b></PTitle>
                                <PDescription><b>Le prix débute à 1000€ TTC pour la création d'un site sur-mesure et 3000€ TTC pour une application web / SaaS.</b><br></br><br></br>
                                L'estimation du temps de travail est appuyé sur un devis avec une maintenance annuelle possible (engagement sur 12 mois) ou à taux horaire. Le paiement est possible en plusieurs échéances.</PDescription>
                            </P1>

                            <P2>
                                <PTitle style={{marginBottom:"0.5rem", color:"rgb(144, 200, 253)"}}><b>Prestation Robotique.</b></PTitle>
                                <PDescription>Pour un projet robotique, <b>contactez moi directement</b> afin d'identifier vos besoins étant donner la nature variée et complexe de certaines applications.</PDescription> 
                            </P2>

                        </ContainerDIVBlockSub1>

                        <ContainerDIVBlockSub2>

                            <P1>
                                <PTitle style={{marginBottom:"0.5rem"}}><b>Développements spécifiques.</b></PTitle>
                                <PDescription>Je propose mes services sur la base d'un <b>taux journalier fixe de 300€ TTC avec une variable</b> selon les demandes et les choix du client (technologie numérique utiliser dans le cadre de la prestation entre autres).</PDescription>
                            </P1>

                            <P2>
                                <PTitle style={{marginBottom:"0.5rem"}}><b>Droit d'auteur & propriété intellectuelle.</b></PTitle>
                                <PDescription>Vous êtes à la fin du projet, propriétaire de l'ensemble des sources (fichiers) composant celui-ci.</PDescription>
                            </P2>

                        </ContainerDIVBlockSub2>

                    </ContainerDIVBlock>

                </TextTitleContainerSub>
            </TextTitleContainer>


    </GlobalContainer>
    <DivCutSection $IsSpecialSocialBrowser={IsSpecialSocialBrowser}>
        <Pcut><b>Des solutions numériques sur-mesure adaptées à vos besoins !</b></Pcut>
    </DivCutSection>
    </div>
    );
});

export default ContactComponent
